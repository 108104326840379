.transponders_table {
  grid-area: info;
  width: 100%;
}

.endRow{
  padding-bottom: 2%;
}

hr {
  width: 80%;
  margin-bottom: 2%;
  margin-left: 3%;

}

.table_boarder{
  border: solid;
  border-color: darkblue;
  border-radius: 3ch;
}

.transponders_box {
  margin: auto;
  width: 100%;
  margin-left: 3%;
  margin-top: 3%;
  font-size: 120%;
}

.Transponders_table{
  margin-top: 5%;
  width: 100%;
}

.button {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: rgb(24, 2, 102);
  background-color: #ffe282c9;
  border: none;
  border-radius: 15px;
}

.button:hover {background-color: #e9b712}

.button:active {
  background-color: #ebaf0a;
  transform: translateY(4px);
}

.comment{
  color: red;
  margin-top: 4%;
}

.dateTimeBoxesDetection{
  margin-bottom: 1%;
  margin-left: 35%;
}

.detection{
  text-align: center;
  margin-top: 3%;
}

.linkPointer{
  cursor: pointer;
  font-weight: bold;
  color: rgb(24, 2, 102);
}

@media only screen and (max-width: 900px) {
    .transponders_table {
      grid-area: info;
      width: 100%;
    }
    
    .table_boarder{
      border: solid;
      border-color: darkblue;
      border-radius: 3ch;
      width: 90%;
    }
    
    .transponders_box {
      margin: auto;
      width: 100%;
      margin-left: 4%;
      margin-top: 3%;
      font-size: 100%;
      }
  }