.more_info_button{
    margin:auto;
    display: block;
}

.boat_box{
    text-align: center;
    display: grid;
    grid-template-columns: 30% 50% 20%;
    height: 20%;
    width: 80%;
    margin: auto;
    margin-top: 1%;
    border: solid;
    border-color: darkblue;
}

.boat_info{
    margin: auto;
}

@media only screen and (max-width: 930px) {
    .boat_box{
        display: grid;
        grid-template-columns: auto;
        height: 50%;
        width: 98%;
    
    }
  }