.registration-container {
    width: 70%;
    margin: 5% auto;
}

.steps-form{
    margin: 30px 0 20px 0;
}

.ant-form-item{
    margin-bottom: 10px !important;
}

.Selects{
    height: 35px;
    width: 95%;
    cursor: pointer;
   }
.Selects:hover{
    color: #2153FA;
   }
.font_grey{
    color: #B2B5BB;
}
.font_black{
    color: black;
}