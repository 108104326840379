.info {
    grid-area: info;
}
.big_map {
    grid-area: map;
}
.buttons {
    grid-area: buttons;
}

.time {
    grid-area: time;
}

.type {
    grid-area: type;
}

.long {
    grid-area: long;
}

.lat {
    grid-area: lat;
}

.container_boat  {
    display: grid;
    grid-template-columns: 35% 35% 35%;
    grid-template-rows: auto;
    grid-template-areas: 
        "info map map"
        "info map map";
    margin: auto;
    margin-left: 8%;
    margin-top: 3%;
}

.container_table  {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas: 
        "type time time long lat"
        "type time time long lat";
    margin: auto;
    margin-left: 8%;
    margin-top: 3%;
}


.extra_button{
    margin-top: 1%;
    margin-bottom: 7%;
    margin-left: 5%;
}

@media only screen and (max-width: 900px) {
    .info {
        grid-area: info;
        text-align: center;
    }
    .big_map {
        grid-area: map;
        margin: auto;
    }
    .buttons {
        grid-area: buttons;
    }
    
    .container_boat {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "info"
            "map";
        margin: auto;
        margin-left: 2%;
    }

    .map{
        margin: auto;
    }
  }