.header{
    background-color: #030F37;
    width: 96.4%;
    padding: 1.8%;
    text-align: center;
}

.menu_element{
    padding-top: 15px;
    color: antiquewhite;
    text-decoration: none;
    float:left;
    text-align: center;
}

.mini_menu_element, .mini_menu_element:hover{
    text-decoration: none;
    color: inherit;
}

.menu_element_none{
    display: none;
}

#login_b, #logout_b{
    width: max-content;
    cursor: pointer;
}

.menu_elemnts{
    padding-left: 17%;
}

.headerMenus{
    margin-left: 3%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 9%;
    background-color: #030F37;
    color: white;
    text-align: center;
 }

.footer div{
    line-height: 65px;
}

 .footer_element{
    color: antiquewhite;
    text-decoration: none;
    text-align: center;
    padding-left: 1%;
    padding-right: 1%;
 }

 .footer_element:hover{
    font-weight: bold;
 }

 .pingme_word{
    color: red;
    float: left;
    margin-top: -1%;
 }

 .title{
     margin-left: 3%;
 }

 @media only screen and (max-width: 1400px) {
    .header{
        background-color: #030F37;
        width: 95.6%;
        padding: 2.2%;
        padding-bottom: 2.8%;
        text-align: center;
    }
    
    .menu_element{
        margin-top: -3%;
        float: inline-end;
        color: antiquewhite;
        text-decoration: none;
    }
 }

 @media only screen and (max-width: 1100px) {

    .pingme_word{
        color: red;
        float: right;
        margin-right: 8%;
        margin-top: -0.03%;
     }

    .hamburger{
        display: block;
        cursor: pointer;
    }

    .menu_element_none{
        display: none;
    }

    .menu_element_active{
        position: relative;
        display: flex;
        float: inline-end;
        text-decoration: none;
        color: antiquewhite;
        text-align: left;
    }
      
    .bar1, .bar2, .bar3 {
        display: block;
        width: 25px;
        height: 3.5px;
        background-color: wheat;
        margin: 3px 0;
    }
    
    .bar1_active {
        width: 25px;
        height: 3.5px;
        background-color: wheat;
        margin: 3px 0;
        -webkit-transform: rotate(-45deg) translate(-5px, 4px);
        transform: rotate(-45deg) translate(-5px, 4px);
    }
    
    .bar2_active {
        width: 25px;
        height: 3.5px;
        background-color: wheat;
        margin: 3px 0;
        opacity: 0;
    }
    
    .bar3_active {
        width: 25px;
        height: 3.5px;
        background-color: wheat;
        margin: 3px 0;
        -webkit-transform: rotate(45deg) translate(-5px, -5px);
        transform: rotate(45deg) translate(-5px, -5px);
    }
 }

 @media only screen and (max-width: 400px) {
    .menu_element_none{
        display: none;
    }

    .header{
        width: 100%;
    }
 }