.map_container{
    padding-bottom: 2%;
}

.map-container{
  width: 20vw;
  height: 20vh;
}

.map-container2{
  width: 57vw;
  height: 60vh;
}

.markerClusterSmall {
   border-radius: 20px;
   font-size: 0.6vw;
   color: rgb(234, 165, 15);
   background-color: darkblue;
   font-weight: 900;
   font-family: Open Sans;
   text-transform: uppercase;
}
.markerClusterMedium {
   border-radius: 40px;
   font-size: 0.6vw;
   color: rgb(234, 165, 15);
   font-weight: 900;
   font-family: Open Sans;
   text-transform: uppercase;
}
.markerClusterLarge {
   border-radius: 70px;
   font-size: 0.6vw;
   color: rgb(234, 165, 15);
   font-weight: 900;
   font-family: Open Sans;
   text-transform: uppercase;
}
.markerClusterLargeXL {
   border-radius: 70px;
   font-size: 0.6vw;
   color: #fff;
   font-weight: 900;
   font-family: Open Sans;
   text-transform: uppercase;
}
.markerClusterLabel {
   display: flex;
   justify-content: center;
   align-items: center;
}

@media only screen and (max-width: 1300px) {
   .map-container{
     width: 40vw;
     height: 30vh;
   }
 }

 @media only screen and (max-width: 990px) {
   .map-container2{
     width: 90vw;
   }
 }

@media only screen and (max-width: 930px) {
    .map_container{
        height: 100%;
        margin: auto;
    }

    .map-container{
      width: 90vw;
      height: 30vh;
    }

    .map-container2{
      width: 90vw;
      height: 45vh;
    }
    
  }