.voyagePage{
    text-align: center;
}

#voyageMap{
    margin: auto;
}

.dateTimeBoxes{
    margin-left: 35%;
    margin-bottom: 1%;
}

@media only screen and (max-width: 1500px) {
    #voyageMap{
        width: 100%;
    }
  }